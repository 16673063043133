import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DishDetailsURL } from "../../component/config/config";
import { AddMenuDetailsURL } from "../../component/config/config";
import moment from "moment-timezone";
import { ImageURL } from "../../component/config/config";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { tostS, tostE } from "../../toast";
import axiosInstance from "../config/axios";
import {
  datefiltered,
  orderedDate,
  filterPassedTime,
} from "../../utility/DateFormat";

const AddMenuDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const current_date = new Date();
  let current_hours = current_date.getHours();
  let current_min = current_date.getMinutes();
  let { type } = useParams();
  const { state } = useLocation();
  const [menuDetails, setMenuDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [timeError, setTimeError] = useState(false);

  const fetchData = async () => {
    let pastData = location.state.menu;
    setLoading(true);
    axiosInstance()
      .post(DishDetailsURL, { dishIds: state.dishIds })
      .then((response) => {
        let arr = [];
        response.data.data.map((dish) => {
          let prevDish = pastData && pastData.filter((D) => D._id === dish._id);
          if (
            prevDish !== null &&
            prevDish !== undefined &&
            prevDish.length > 0
          ) {
            arr.push({ ...prevDish[0] });
            setChangeDate(prevDish[0].orderByDate);
            setChangeTime(prevDish[0].orderByTime);
            setChangeDelivery(prevDish[0].deliveryOrPickupWindow);
          } else {
            arr.push({
              ...dish,
              totalServings: 0,
              orderByDate: "",
              orderByTime: "",
              deliveryOrPickupWindow: [
                {
                  title: "10am-1pm",
                  status: false,
                },
                {
                  title: "4pm-7pm",
                  status: false,
                },
              ],
            });
          }
        });
        setMenuDetails(arr);
        // setChangeDate(arr.orderByDate)
        // setChangeTime(arr.orderByTime)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const submit = (e) => {
    e.preventDefault();
    document.body.classList.add("loading-indicator");
    let arr = [];
    arr = menuDetails.map((dish) => {
      let windowData = [];
      changeDelivery.map((element) => {
        if (element.status) {
          windowData.push(element.title);
        }
      });
      return {
        dishId: dish._id,
        totalServings: dish.totalServings,
        orderByDate: orderedDate(changeDate, changeTime),
        orderByTime: {
          hours: moment(changeTime).format("h"),
          minutes: moment(changeTime).format("mm"),
          format: moment(changeTime).format("a"),
        },
        deliveryOrPickupWindow: windowData,
      };
    });
    let isValid = arr.map(
      (_, i) =>
        arr[i]?.totalServings != 0 &&
        arr[i].deliveryOrPickupWindow.length > 0 &&
        changeDate != "" &&
        changeTime != ""
    );
    if (isValid.every((_) => _) && timeError === false) {
      try {
        setIsDisabled(true);
        axiosInstance()
          .post(AddMenuDetailsURL, {
            dishes: arr,
            menuDate: state.menuDate,
            now: new Date().toLocaleString(),
          })
          .then((response) => {
            document.body.classList.remove("loading-indicator");
            state.menuDate = moment(state.menuDate).format("DD-MMMM-YYYY");
            navigate(`/dashboard/mymenu/${state?.menuDate}`);
            tostS(response.data.message);
          });
      } catch (error) {
        document.body.classList.remove("loading-indicator");
        console.log(error);
        setIsDisabled(false);
        tostE("Please fill details");
      }
    } else {
      document.body.classList.remove("loading-indicator");
      if (timeError) {
        tostE("Please select a valid time.");
      } else {
        tostE("Please fill details");
      }
    }
  };
  const handleupdate = (operation, index, e) => {
    let zipReg = /^\d+$/;
    let v = e.target.value;
    let arr = [...menuDetails];
    if (operation === "add") {
      arr[index].totalServings++;
    }
    if (operation === "minus") {
      arr[index].totalServings--;
    }
    if ((operation === "type" && zipReg.test(v)) || v === "") {
      if (v.length <= 3) {
        arr[index].totalServings = v;
      }
    }
    setMenuDetails(arr);
  };

  const addDish = () => {
    navigate(
      `/dashboard/mymenu/${moment(state.menuDate).format("DD-MMMM-YYYY")}`,
      { state: { data: handleChangedData(), type: "add" } }
    );
  };
  const handleChangedData = () => {
    let arr = [...menuDetails];
    const updateArr = arr.map((item) => ({
      ...item,
      orderByDate: changeDate,
      orderByTime: changeTime,
      deliveryOrPickupWindow: changeDelivery,
    }));
    return updateArr;
  };
  const handleDate = (value, index) => {
    let arr = [...menuDetails];
    arr[index].orderByDate = value;
    setMenuDetails([...arr]);
  };
  const handleTime = (value, index) => {
    let arr = [...menuDetails];
    arr[index].orderByTime = value;
    setMenuDetails([...arr]);
  };
  const handleChangeTags = (i, e, index) => {
    const a = [...changeDelivery];
    a[i].status = e.target.checked;
    setChangeDelivery([...a]);
  };
  let removeFormFields = (i) => {
    let newFormValues = [...menuDetails];
    newFormValues.splice(i, 1);
    setMenuDetails(newFormValues);
  };
  const [changeDate, setChangeDate] = useState("");
  const [changeTime, setChangeTime] = useState("");
  const [changeDelivery, setChangeDelivery] = useState([
    {
      title: "10am-1pm",
      status: false,
    },
    {
      title: "4pm-7pm",
      status: false,
    },
  ]);
  return (
    <>
      <div className="dash-R">
        <div className="dash-head">
          <h3>my menu</h3>
          <nav className="breadcrumb-box">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard/mymenu/all">My Menu</a>
              </li>
              <li className="breadcrumb-item">
                <a>
                  {/* {state.menuDate.charAt(0).includes('-') ? state.menuDate = state.menuDate.slice(1) : ""} */}
                  {moment(current_date).format("DD MMMM") ===
                  moment(state.menuDate).format("DD MMMM")
                    ? "Today"
                    : moment(state.menuDate).format("DD MMMM")}
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Add Menu Details
              </li>
            </ol>
          </nav>
        </div>
        <div className="dash-bottom mt-2 add-dish">
          <div className="row mt-4 delivery-main-head">
            <div className="col-md-3 mb-3">
              <h3 className="inner-title">Delivery/Pick-Up Window</h3>
              <div class="delivery-radio-box">
                {changeDelivery?.map((data, idx) => {
                  return (
                    <label className="coustom-checkbox me-3 mb-0">
                      <input
                        type="checkbox"
                        checked={data.status}
                        value={data.status}
                        onChange={(e) => handleChangeTags(idx, e)}
                      />
                      <span className="checkmark"></span>
                      <p>{data.title}</p>
                    </label>
                  );
                })}
              </div>
            </div>
            <div className="col-md-3">
              <div class="inner-title-top-dish">
                <h3 className="inner-title">
                  ORDER BY DATE
                  <span className="tool-tip-box con-tooltip top">
                    <i className="ri-information-line"></i>
                    {/* <div className="tooltip ">
                      <p>
                        1. For Today’s menu, you can only select today’s date in
                        this field.
                        <br />
                        2. For future date menu, you can select any date before
                        the scheduled order date in this field.
                      </p>
                    </div> */}
                  </span>
                </h3>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {new Date(current_date).toLocaleDateString() ===
                  new Date(state.menuDate).toLocaleDateString() ? (
                    <DatePicker
                      className="form-control"
                      label="Select Date"
                      inputProps={{ readOnly: true }}
                      disablePast
                      maxDate={current_date}
                      showToolbar={false}
                      value={changeDate}
                      onChange={setChangeDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  ) : (
                    <DatePicker
                      className="form-control"
                      label="Select Date"
                      inputProps={{ readOnly: true }}
                      disablePast
                      maxDate={moment(new Date(state.menuDate))}
                      // maxDate={
                      //   moment(new Date(state.menuDate)).subtract(1, "d")._d
                      // }
                      showToolbar={false}
                      value={changeDate}
                      onChange={setChangeDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  )}
                </LocalizationProvider>
              </div>
            </div>
            <div className="col-md-3">
              <div class="inner-title-top-dish my-menu-content">
                <h3 className="inner-title">
                  ORDER BY TIME
                  <span className="tool-tip-box con-tooltip top">
                    <i className="ri-information-line"></i>
                    <div className="tooltip " data-placement="bottom">
                      <p>
                        1. If Order by Date and Menu Date are the same:
                        <br />
                        1.1 For Delivery/Pick-up window of 10am-1pm, you can
                        select ‘Order By Time’ up to 9:30am, for timely pick-up
                        and deliveries
                        <br />
                        1.2 For Delivery/Pick-up window of 4pm-7pm, you can
                        select ‘Order By Time’ up to 3:30pm, for timely pick-up
                        and deliveries
                        <br />
                        2. If Order by Date is before Menu Date, then you can
                        select any time as your Order by time
                      </p>
                    </div>
                  </span>
                </h3>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {new Date(current_date).toLocaleDateString() ===
                  new Date(state.menuDate).toLocaleDateString() ? (
                    // moment(current_date).format("DD-MM-YYYY") === moment(new Date(state.menuDate?.split("00").join(""))).format("DD-MM-YYYY")
                    <DesktopTimePicker
                      className="form-control"
                      label="Select Time"
                      minTime={new Date(0, 0, 0, current_hours, current_min)} // 7:00am
                      maxTime={
                        changeDelivery[0].status === true &&
                        changeDelivery[1].status === false
                          ? new Date(0, 0, 0, 9, 30)
                          : new Date(0, 0, 0, 3 + 12, 30)
                      }
                      inputProps={{ readOnly: true }}
                      value={changeTime}
                      onError={(reason, value) => {
                        if (reason == "maxTime" || reason == "minTime") {
                          setTimeError(true);
                        }
                        if (reason == null) {
                          setTimeError(false);
                        }
                      }}
                      onChange={setChangeTime}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  ) : (
                    <DesktopTimePicker
                      className="form-control"
                      label="Select Time"
                      inputProps={{ readOnly: true }}
                      value={changeTime}
                      minTime={
                        moment(current_date).format("MM/DD/YYYY") !=
                        moment(changeDate).format("MM/DD/YYYY")
                          ? ""
                          : new Date(0, 0, 0, current_hours, current_min)
                      }
                      // maxTime={
                      //   changeDelivery[0].status === true &&
                      //   changeDelivery[1].status === false
                      //     ? new Date(0, 0, 0, 9, 30)
                      //     : new Date(0, 0, 0, 3 + 12, 30)
                      // }
                      maxTime={
                        changeDelivery[0].status === true &&
                        changeDelivery[1].status === false
                          ? moment(new Date(state.menuDate)).format(
                              "MM/DD/YYYY"
                            ) ===
                            moment(new Date(changeDate)).format("MM/DD/YYYY")
                            ? new Date(0, 0, 0, 9, 30)
                            : ""
                          : moment(new Date(state.menuDate)).format(
                              "MM/DD/YYYY"
                            ) ===
                            moment(new Date(changeDate)).format("MM/DD/YYYY")
                          ? new Date(0, 0, 0, 3 + 12, 30)
                          : ""
                      }
                      onChange={setChangeTime}
                      onError={(reason, value) => {
                        if (reason == "maxTime" || reason == "minTime") {
                          setTimeError(true);
                        }
                        if (reason == null) {
                          setTimeError(false);
                        }
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  )}
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <p className="messageText">
            Please select the number of servings available for each dish.
          </p>

          <div class="dish-list">
            <div class="dish-list-inner">
              <div className="row dish-action-box mt-5">
                <div className="col-md-3 col-5 all-dish-item col-xl-4">
                  <h3 className="inner-title">DISH NAME</h3>
                </div>
                <div className="col-md-2 col-4 all-dish-item ">
                  <h3 className="inner-title">SERVINGS</h3>
                </div>
                <div className="col-md-3 col-3 all-dish-item ">
                  <h3 className="inner-title">Actions</h3>
                </div>
              </div>
              {menuDetails.map((damm, index) => {
                return (
                  <>
                    <div
                      className="row dish-action-box mb-3 align-items-center"
                      key={index}
                    >
                      <div className="col-md-3 col-5 all-dish-item col-xl-4">
                        <div className="add-dish-list ">
                          <div className="dish-name">
                            <div className="dish-name-content">
                              <figure>
                                <img src={ImageURL + damm.dishImage} />
                              </figure>
                              <figcaption>
                                <h3 style={{ wordBreak: "break-all" }}>
                                  {damm.name}
                                </h3>
                              </figcaption>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-4  all-dish-item all-items-delete  ">
                        <div className="Servings">
                          <div className="d-flex align-items-center">
                            <div
                              className={
                                damm.totalServings === 0
                                  ? "number number-unactive"
                                  : "number"
                              }
                            >
                              <span className="minus">
                                {damm.totalServings != 0 ? (
                                  <i
                                    className="ri-subtract-fill"
                                    onClick={(e) =>
                                      handleupdate("minus", index, e)
                                    }
                                  ></i>
                                ) : null}
                              </span>
                              <input
                                type="text"
                                name="servings"
                                value={damm.totalServings}
                                onChange={(e) => handleupdate("type", index, e)}
                                min={0}
                                maxLength={3}
                              />
                              <span className="plus">
                                <i
                                  className="ri-add-line"
                                  onClick={(e) => handleupdate("add", index, e)}
                                ></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-3 all-dish-item all-items-delete ">
                        <div className="">
                          <a
                            type="button"
                            className="delete-btn hvr-sweep-to-right"
                            data-bs-toggle="modal"
                            data-bs-target="#delete-menu"
                            onClick={() => removeFormFields(index)}
                          >
                            <i className="ri-delete-bin-fill"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <div className="mt-5 btn-group-add d-flex">
            {menuDetails?.length !== 0 &&
            menuDetails?.length !== undefined &&
            location.state.status != 5 ? (
              <>
                <button
                  className="btn purple-btn min-height hvr-sweep-to-right  me-3"
                  onClick={submit}
                  disabled={isDisabled}
                >
                  Save
                </button>{" "}
              </>
            ) : (
              ""
            )}
            {location.state.status != 5 ? (
              <a
                className="btn purple-btn min-height hvr-sweep-to-right trans-btn"
                onClick={() => addDish()}
              >
                Add More Dishes
              </a>
            ) : (
              <a className="btn purple-btn min-height hvr-sweep-to-right trans-btn">
                Update
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AddMenuDetails;
