import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import moment from "moment-timezone";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ImageURL, UpdateMenuURL } from "../../component/config/config";
import axiosInstance from "../config/axios";
import { tostE, tostS } from "../../toast";
import { DishDetailsURL } from "../../component/config/config";

const UpdateMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showData, setShowData] = useState(location.state.data);
  const [changeDate, setChangeDate] = useState(
    location.state.data[0].orderByDate
  );
  const current_date = new Date();
  const { state } = useLocation();
  let { type } = useParams();
  let current_hours = current_date.getHours();
  let current_min = current_date.getMinutes();
  const [timeError, setTimeError] = useState(false);
  const [dateError, setDateError] = useState(false);

  const fetchData = async () => {
    let pastData = location.state.data;
    let dish = location.state.dishIds;
    if (location.state.menuDate) {
      axiosInstance()
        .post(DishDetailsURL, { dishIds: dish })
        .then((response) => {
          let arr = [];
          response.data.data.map((dish) => {
            let prevDish =
              pastData && pastData.filter((D) => D._id === dish._id);
            if (
              prevDish !== null &&
              prevDish !== undefined &&
              prevDish.length > 0
            ) {
              arr.push({ ...prevDish[0] });
            } else {
              arr.push({
                ...dish,
                totalServings: 0,
                orderByDate: showData[0].orderByDate,
                orderByTime: showData[0].orderByTime,
                deliveryOrPickupWindow: showData[0].deliveryOrPickupWindow.map(
                  (data) => data
                ),
              });
            }
          });
          setShowData(arr);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axiosInstance()
        .post(DishDetailsURL, { dishIds: state.data.map((da) => da._id) })
        .then((response) => {
          let arr = [];
          response.data.data.map((dish) => {
            let prevDish =
              pastData && pastData.filter((D) => D._id === dish._id);
            if (
              prevDish !== null &&
              prevDish !== undefined &&
              prevDish.length > 0
            ) {
              arr.push({ ...prevDish[0] });
            } else {
              arr.push({
                ...dish,
                totalServings: 0,
                orderByDate: "",
                orderByTime: "",
                deliveryOrPickupWindow: [
                  {
                    title: "10am-1pm",
                    status: false,
                  },
                  {
                    title: "4pm-7pm",
                    status: false,
                  },
                ],
              });
            }
          });
          setShowData(arr);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    let tagArray = changeDelivery.map((dataaa) => {
      if (showData[0]?.deliveryOrPickupWindow?.includes(dataaa.title)) {
        return {
          title: dataaa.title,
          status: true,
        };
      } else {
        return dataaa;
      }
    });
    setChangeDelivery([...tagArray]);
    fetchData();
  }, []);
  const handleupdate = (operation, index, e) => {
    let zipReg = /^\d+$/;
    let v = e.target.value;
    let arr = [...showData];
    if (operation === "add") {
      arr[index].totalServings++;
    }
    if (operation === "minus") {
      arr[index].totalServings--;
    }
    if ((operation === "type" && zipReg.test(v)) || v === "") {
      if (v.length <= 3) {
        arr[index].totalServings = v;
      }
    }
    setShowData(arr);
  };
  const handleDate = (date) => {
    setChangeDate(date);
  };
  const handleTime = (time) => {
    setChangeDate(time);
  };

  const handleChangeTags = (i, e, index) => {
    const a = [...changeDelivery];
    a[i].status = e.target.checked;
    setChangeDelivery([...a]);
  };
  const [changeDelivery, setChangeDelivery] = useState([
    {
      title: "10am-1pm",
      status: false,
    },
    {
      title: "4pm-7pm",
      status: false,
    },
  ]);

  const submit = (e) => {
    e.preventDefault();
    let arr = [];
    arr = showData.map((dish) => {
      let windowData = [];
      changeDelivery.map((element) => {
        if (element.status) {
          windowData.push(element.title);
        }
      });
      return {
        dishId: dish._id,
        totalServings: dish.totalServings,
        orderByDate:
          new Date(changeDate).toDateString() +
          " " +
          new Date(changeDate).toTimeString(),
        orderByTime: {
            hours: moment(changeDate).format("h"),
            minutes: moment(changeDate).format("mm"),
            format: moment(changeDate).format("a"),
        },
        deliveryOrPickupWindow: windowData,
      };
    });

    let isValid = arr.map(
      (_, i) =>
        arr[i]?.totalServings != 0 && arr[i].deliveryOrPickupWindow.length > 0
    );
    // let id = showData[0].menuId ? showData[0].menuId : showData[showData.length - 1].menuId
    let id = showData.filter((x) => x.menuId);
    if (isValid.every((_) => _) && timeError === false && dateError === false) {
      // try {
      axiosInstance()
        .post(UpdateMenuURL, {
          _id: localStorage.getItem("menuId")
            ? localStorage.getItem("menuId")
            : id[0].menuId,
          dishes: arr,
        })
        .then((response) => {
          if (response.status === 200) {
            navigate(`/dashboard/mymenu/${type}`);
            tostS(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          tostE(error?.response?.data?.message);
        });
    } else {
      if (timeError) {
        tostE("Please select a valid time.");
      } else if (dateError) {
        tostE("Please select a valid date.");
      } else {
        tostE("Please fill details");
      }
    }
  };
  const addDish = () => {
    // state.menuDate = moment(state.menuDate).format("DD-MMMM-YYYY")
    navigate(`/dashboard/mymenu/${type}`, {
      state: { data: showData, type: "update" },
      replace: true,
    });
    // console.log("1", state)
  };
  //   const one = new Date(type) - 1;
  const one = new Date(type);

  return (
    <>
      <div className="dash-R">
        <div className="dash-head">
          <h3>my menu</h3>
          <nav className="breadcrumb-box">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard/mymenu/all">My Menu</a>
              </li>
              <li className="breadcrumb-item">
                <a>
                  {moment(current_date).format("DD MMMM") ===
                  moment(type).format("DD MMMM")
                    ? "Today"
                    : moment(type).format("DD MMMM")}{" "}
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {" "}
                Update Menu Details
              </li>
            </ol>
          </nav>
        </div>
        <div className="dash-bottom mt-2 add-dish">
          {/* <p className="messageText">
                        Please select the number of servings available for each dish.
                    </p> */}
          {showData.map((data, index) => {
            return (
              <>
                <div className="row">
                  <div className="col-md-3">
                    {index == 0 ? (
                      <>
                        <h3 className="inner-title">Delivery/Pick-Up Window</h3>

                        <div class="delivery-radio-box">
                          {changeDelivery?.map((daa, idx) => {
                            return (
                              <label className="coustom-checkbox me-3">
                                <input
                                  type="checkbox"
                                  checked={daa.status}
                                  value={daa.status}
                                  onChange={(e) => handleChangeTags(idx, e)}
                                />
                                <span className="checkmark"></span>
                                <p>{daa.title}</p>
                              </label>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-3">
                    <div class="inner-title-top-dish">
                      {index === 0 ? (
                        <>
                          <h3 className="inner-title">
                            ORDER BY DATE
                            <span className="tool-tip-box con-tooltip top">
                              <i className="ri-information-line"></i>
                              {/* <div className="tooltip ">
                                <p>
                                  1. For Today’s menu, you can only select
                                  today’s date in this field.
                                  <br />
                                  2. For future date menu, you can select any
                                  date before the scheduled order date in this
                                  field.
                                </p>
                              </div> */}
                            </span>
                          </h3>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            {new Date(current_date).toLocaleDateString() ===
                            new Date(type).toLocaleDateString() ? (
                              // moment(current_date).format("DD MMMM") === moment(type).format("DD MMMM")
                              <DatePicker
                                className="form-control"
                                label="Select Date"
                                disablePast
                                maxDate={current_date}
                                inputProps={{ readOnly: true }}
                                showToolbar={false}
                                value={changeDate}
                                onError={(reason, value) => {
                                  if (reason == "disablePast") {
                                    setDateError(true);
                                  }
                                  if (reason == null) {
                                    setDateError(false);
                                  }
                                }}
                                onChange={(date) => handleDate(date)}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            ) : (
                              <DatePicker
                                className="form-control"
                                label="Select Date"
                                inputProps={{ readOnly: true }}
                                showToolbar={false}
                                disablePast
                                maxDate={moment(one)
                                  .format("MM/DD/YYYY")
                                  .split("-")
                                  .join("")}
                                value={changeDate}
                                onChange={(date) => handleDate(date)}
                                onError={(reason, value) => {
                                  if (reason == "disablePast") {
                                    setDateError(true);
                                  }
                                  if (reason == null) {
                                    setDateError(false);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            )}
                          </LocalizationProvider>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="inner-title-top-dish">
                      {index === 0 ? (
                        <>
                          <h3 className="inner-title">
                            ORDER BY TIME
                            <span className="tool-tip-box con-tooltip top">
                              <i className="ri-information-line"></i>
                              <div className="tooltip " data-placement="bottom">
                                <p>
                                  1. If Order by Date and Menu Date are the
                                  same:
                                  <br />
                                  1.1 For Delivery/Pick-up window of 10am-1pm,
                                  you can select ‘Order By Time’ up to 9:30am,
                                  for timely pick-up and deliveries
                                  <br />
                                  1.2 For Delivery/Pick-up window of 4pm-7pm,
                                  you can select ‘Order By Time’ up to 3:30pm,
                                  for timely pick-up and deliveries
                                  <br />
                                  2. If Order by Date is before Menu Date, then
                                  you can select any time as your Order by time
                                </p>
                              </div>
                            </span>
                          </h3>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            {new Date(current_date).toLocaleDateString() ===
                            new Date(type).toLocaleDateString() ? (
                              // moment(current_date).format("DD MMMM") === moment(type).format("DD MMMM")
                              <DesktopTimePicker
                                className="form-control"
                                label="Select Time"
                                inputProps={{ readOnly: true }}
                                minTime={
                                  new Date(0, 0, 0, current_hours, current_min)
                                }
                                maxTime={
                                  changeDelivery[0].status === true &&
                                  changeDelivery[1].status === false
                                    ? new Date(0, 0, 0, 9, 30)
                                    : new Date(0, 0, 0, 3 + 12, 30)
                                }
                                value={changeDate}
                                onError={(reason, value) => {
                                  if (
                                    reason == "maxTime" ||
                                    reason == "minTime"
                                  ) {
                                    setTimeError(true);
                                  }
                                  if (reason == null) {
                                    setTimeError(false);
                                  }
                                }}
                                onChange={(time) => handleTime(time)}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            ) : (
                              <DesktopTimePicker
                                className="form-control"
                                label="Select Time"
                                inputProps={{ readOnly: true }}
                                minTime={
                                  moment(current_date).format("MM/DD/YYYY") !=
                                  moment(changeDate).format("MM/DD/YYYY")
                                    ? ""
                                    : new Date(
                                        0,
                                        0,
                                        0,
                                        current_hours,
                                        current_min
                                      )
                                }
                                // maxTime={
                                //   changeDelivery[0].status === true &&
                                //   changeDelivery[1].status === false
                                //     ? new Date(0, 0, 0, 9, 30)
                                //     : new Date(0, 0, 0, 3 + 12, 30)
                                // }
                                maxTime={
                                  changeDelivery[0].status === true &&
                                  changeDelivery[1].status === false
                                    ? moment(new Date(type)).format(
                                        "MM/DD/YYYY"
                                      ) ===
                                      moment(changeDate).format("MM/DD/YYYY")
                                      ? new Date(0, 0, 0, 9, 30)
                                      : ""
                                    : moment(new Date(type)).format(
                                        "MM/DD/YYYY"
                                      ) ===
                                      moment(changeDate).format("MM/DD/YYYY")
                                    ? new Date(0, 0, 0, 3 + 12, 30)
                                    : ""
                                }
                                // value={`${location.state?.orderByTime?.hours}` + `:${location.state?.orderByTime?.minutes}` + " " + location.state?.orderByTime?.format}
                                // value={moment(location.state.orderByTime).format("YYYY-MM-DD'T'HH:MM:SS.SSSZ")}
                                onError={(reason, value) => {
                                  if (
                                    reason == "maxTime" ||
                                    reason == "minTime"
                                  ) {
                                    setTimeError(true);
                                  }
                                  if (reason == null) {
                                    setTimeError(false);
                                  }
                                }}
                                value={changeDate}
                                onChange={(time) => handleTime(time)}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            )}
                          </LocalizationProvider>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {index === 0 ? (
                  <p className="messageText">
                    Please select the number of servings available for each
                    dish.
                  </p>
                ) : (
                  ""
                )}
                <div className="row mt-4">
                  <div className="col-md-4 all-dish-item">
                    {index === 0 ? (
                      <h3 className="inner-title">DISH NAME</h3>
                    ) : (
                      ""
                    )}
                    <div className="add-dish-list mb-3 ">
                      <div className="dish-name">
                        <div className="dish-name-content">
                          <figure>
                            <img src={ImageURL + data.dishImage} />
                          </figure>
                          <figcaption>
                            <h3 style={{ wordBreak: "break-all" }}>
                              {data.name}
                            </h3>
                          </figcaption>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2  all-dish-item all-items-delete">
                    <div className="Servings">
                      {index === 0 ? (
                        <h3 className="inner-title">SERVINGS</h3>
                      ) : (
                        ""
                      )}
                      <div className="d-flex align-items-center mt-4">
                        <div
                          className={
                            location.state.totalServings === 0
                              ? "number number-unactive"
                              : "number"
                          }
                        >
                          <span className="minus">
                            {data.totalServings != 0 ? (
                              <i
                                className="ri-subtract-fill"
                                onClick={(e) => handleupdate("minus", index, e)}
                              ></i>
                            ) : null}
                          </span>

                          <input
                            type="text"
                            name="servings"
                            value={data.totalServings}
                            onChange={(e) => handleupdate("type", index, e)}
                            min={0}
                            maxLength={3}
                          />
                          <span className="plus">
                            <i
                              className="ri-add-line"
                              onClick={(e) => handleupdate("add", index, e)}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
          <div className="mt-5 btn-group-box">
            <a
              className="btn purple-btn min-height hvr-sweep-to-right  me-3"
              onClick={submit}
            >
              Update
            </a>
            <a
              className="btn purple-btn min-height hvr-sweep-to-right trans-btn"
              onClick={() => addDish()}
            >
              Add More Dishes
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateMenu;
