import React, { useState, useRef } from "react";
import { useFormik, Form, Field, ErrorMessage, FormikProvider } from "formik";
import * as Yup from "yup";
import { CuisinesURL, ImageURL } from "../../component/config/config";
import { FoodCategoryURL } from "../../component/config/config";
import { AddishURL, ExtensionChangeURL } from "../../component/config/config";
import axiosInstance from "../config/axios";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Avatar } from "@material-ui/core";
import authHeader from "../services/auth_header";
import { NavLink, useNavigate } from "react-router-dom";
import { tostS, tostE } from "../../toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useSelector } from "react-redux";

const AddDish = () => {
  const LoginData = useSelector((state) =>
    state.login.data && state.login.data.chefDetails ? state.login.data : {}
  );
  const chefID = LoginData?.chefDetails?.chefID;
  const [cuisineType, setCuisineType] = useState();
  const [foodType, setFoodType] = useState();
  const [formValues, setFormValues] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [image, setImage] = useState("");
  const navigate = useNavigate();

  const grip = <FontAwesomeIcon icon={faGripLines} />;

  const commission = useSelector((state) => state.commissionReducer);
  const chefCommission = commission?.commission?.commission
    ? commission?.commission?.commission
    : commission?.commission?.globalCommission;
    
  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragStart = (e, position) => {
    dragItem.current = position;
  };
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };
  const drag = (e) => {
    const copyItems = [...formValues];
    const dragItemContent = copyItems[dragItem.current];
    copyItems.splice(dragItem.current, 1);
    copyItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setFormValues(copyItems);
  };
  const [tagsData, setTagsData] = useState([
    {
      title: "Dairy Free",
      status: false,
    },
    {
      title: "Gluten Free",
      status: false,
    },
    {
      title: "Nut Free",
      status: false,
    },
    {
      title: "Keto-Friendly",
      status: false,
    },
    {
      title: "Low Carb",
      status: false,
    },
    {
      title: "Organic",
      status: false,
    },
    {
      title: "Vegan",
      status: false,
    },
    {
      title: "Vegetarian",
      status: false,
    },
    {
      title: "Halal",
      status: false,
    },
    {
      title: "Kosher",
      status: false,
    },
  ]);
  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i] = e.target.value;
    setFormValues(newFormValues);
    setFieldValue("ingredients", newFormValues);
  };
  const inputRef = useRef(null);
  //For add a ingredients field
  let addFormFields = () => {
    setFormValues([...formValues, ""]);
    setFieldValue("ingredients", [...formValues, ""]);
    inputRef?.current?.focus();
  };
  //For remove a ingredients field
  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    setFieldValue("ingredients", newFormValues);
  };
  const fetchData = async () => {
    const foodresponse = await axios.get(FoodCategoryURL);
    const cuisineresponse = await axios.get(CuisinesURL);
    setFoodType(foodresponse?.data?.data);
    setCuisineType(cuisineresponse?.data?.data);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const COUPONCODE = /^[0-9a-zA-Z]*$/;

  const myValidationSchema = Yup.object().shape({
    dishImage: Yup.mixed().required("Dish Image is required"),
    name: Yup.string()
      // .strict(true).trim("Extra spaces should be truncated")
      .min(3, " Dish Name must be at least 3 characters ")
      .max(55, " Dish Name must be less than 55 characters ")
      .required("Dish Name is  required")
      .test(
        "trim",
        "Dish Name is not valid",
        (val) =>
          val !== undefined &&
          !val.match(
            /^[0-9][$&+,:;=?@#|'<>.^*()%!-]*$/,
            "Dish name is not valid"
          )
      ),
    cuisine: Yup.string().required("Cuisine is required "),
    price: Yup.string()
      // .strict(true).trim("Extra spaces should be truncated")
      .min(1, " Price must be at least 1 digits ")
      .max(8, " Price not more than 8 digits ")
      .required("Price is required ")
      .test(
        "is-decimal",
        "Price should be valid",
        (val) => val !== undefined && val.match(/^[0-9]+(\.[0-9]{1,2})?$/)
      )
      .test(
        "maxFourDigits",
        "Price should be more than 0 ",
        (number) => Number(number) > 0
      ),
    foodType: Yup.string().required("Food Type is required"),
    portionSize: Yup.string().required("Portion Size is required"),
    // portionSizeCount: Yup.string().required("Portion Size Count is required"),
    preprationTime: Yup.string()
      // .strict(true).trim("Extra spaces should be truncated")
      .required("Preparation Time is required")
      .matches(/^\d+$/, "Enter valid Preparation Time")

      .test(
        "maxTwoDigits",
        "Preparation Time should not be more than 120 minutes",
        (number) => Number(number) <= 120
      ),
    description: Yup.string()
      .min(5, " Dish Description must be at least 5 characters ")
      .max(750, "Dish Description cannot be more than 750 characters ")
      .required("Description is required "),
    ingredients: Yup.array()
      .min(1, "Ingredient(s) is/are required")
      .of(
        Yup.string()
          .max(30, " Ingredient(s) name must not be more than 30 characters ")
          .required("Ingredient is required ")
      ),
  });

  const formik = useFormik({
    initialValues: {
      imagePath: "",
      price: "",
      cuisine: "",
      name: "",
      foodType: "",
      portionSize: "",
      description: "",
      ingredients: formValues,
      restrictedDiets: [],
      preprationTime: "",
      dishImage: "",
      // portionSizeCount: "",
    },
    validationSchema: myValidationSchema,
    // { isSubmitting === formik.errors ? console.log("submit") : tostE("please wait") }
    onSubmit: async (values) => {
      {
      }
      let tags = [];
      tagsData.map((element) => {
        if (element.status) {
          tags.push(element.title);
        }
      });
      let unique = [];
      for (let i = 0; i < formValues.length; i++) {
        if (unique.indexOf(formValues[i]) === -1) {
          unique.push(formValues[i]);
        }
      }
      console.log(unique);
      try {
        let formData = new FormData();
        formData.append("price", values?.price);
        formData.append("cuisine", values?.cuisine);
        formData.append("name", values?.name?.trim());
        formData.append("foodType", values?.foodType);
        formData.append("portionSize", values?.portionSize);
        // formData.append("portionSizeCount", values?.portionSizeCount);
        formData.append("imagePath", "chef/" + chefID);
        formData.append("description", values?.description.replace(/ +/g, " "));
        formData.append("ingredients", JSON.stringify(unique));
        formData.append("restrictedDiets", JSON.stringify(tags));
        formData.append("preprationTime", values?.preprationTime);
        formData.append("dishImage", values?.dishImage);
        setIsDisabled(true);
        const response = await axiosInstance().post(AddishURL, formData);
        const data = response.data.data;
        if (response.status === 200) {
          tostS(response.data.message);
          navigate("/dashboard/mymenu/all");
        }
      } catch (error) {
        setIsDisabled(false);
        tostE(error.response.data.message);
      }
    },
  });
  let {
    submitCount,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    values,
    setValues,
    isValidating,
  } = formik;
  const handleChangeTags = (i, e) => {
    const a = [...tagsData];
    a[i].status = e.target.checked;
    setTagsData([...a]);
  };

  const priceChange = () => {
    let Z = chefCommission / 100;
    let Y = formik.values.price * Z;
    let X = formik.values.price - Y;
    return X ? X.toFixed(2) : 0;
  };
  function handleEnter(event) {
    if (event.keyCode === 13) {
      // const form = event.target.form;
      // const index = Array.prototype.indexOf.call(form, event.target);
      // form.elements[index + 2].focus();
      event.preventDefault();
      addFormFields();
    }
  }
  const handleTime = (e) => {
    let z = e.target.value;
    let zipReg = /^\d+$/;
    if (zipReg.test(z) || z === "") {
      if (z.length <= 3) {
        setFieldValue("preprationTime", z);
      }
    }
  };

//   const handlePortionSizeCount = (sizeCount) => {
//     setFieldValue("portionSizeCount", sizeCount);
// };

  const profi = async (e) => {
    console.log(e);
    document.body.classList.add("loading-indicator");

    if (
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/jpeg"
    ) {
      if (parseInt(e.target.files[0].size) <= 5145728) {
        document.body.classList.remove("loading-indicator");
        setFieldValue("dishImage", e.target.files[0]);
        setImage(URL.createObjectURL(e.target.files[0]));
      } else {
        tostE("Please upload a file smaller than 5 MB");
      }
    } else if (
      e.target.files[0].type === "image/heif" ||
      e.target.files[0].type === "image/heic" ||
      e.target.files[0].type === ""
    ) {
      let formData = new FormData();
      formData.append("imagePath", "chef/" + chefID);
      formData.append("extension", e.target.files[0]);

      const resp = await axios.post(ExtensionChangeURL, formData);
      document.body.classList.remove("loading-indicator");
      // console.log(resp.data.data)
      setFieldValue("dishImage", resp.data.data[0]);
      setImage(ImageURL + resp.data.data[0]);
    } else {
      document.body.classList.remove("loading-indicator");
      tostE("Please upload a file with png,jpg,heic or jpeg format.");
    }
  };
  const [start, setStart] = useState(false);
  return (
    <>
      {/* {console.log(formValues.length)} */}
      <div className="dash-R">
        <div className="dash-head">
          <h3>my menu</h3>
          <nav className="breadcrumb-box">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink to="/dashboard/mymenu/all">My Menu</NavLink>
              </li>
              <li className="breadcrumb-item">
                <a href="#">All Dishes </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Add New Dish
              </li>
            </ol>
          </nav>
        </div>
        <FormikProvider value={formik}>
          <div className="dash-bottom mt-2">
            <Form
              autoComplete="off"
              noValidate
              // onSubmit={handleSubmit}
              onSubmit={(e) => {
                e.preventDefault();
                console.log(Object.values(errors));
                if (Object.values(errors).length >= 1) {
                  tostE(
                    "There is an error on the form. Please fix and try again"
                  );
                }
                handleSubmit();
              }}
            >
              <div className="form-box row">
                <div className="col-md-9 m-auto">
                  <div className="mb-3">
                    <div className="upload-photo">
                      <div className="avatar-upload">
                        <span className="info-tag tool-tip-box con-tooltip top">
                          <b>*</b>
                          <i className="ri-information-line"></i>
                          <div className="tooltip r-tooltip">
                            <p>
                              Upload a mouth-watering picture of your dish for
                              the customers to see when they search for it.
                              Please refer to the instructions on how to take
                              good food pictures in the FAQ section.
                            </p>
                          </div>
                        </span>
                        <div className="avatar-edit">
                          <input
                            type="file"
                            name="dishImage"
                            id="imageUpload"
                            onChange={(e) => profi(e)}
                            accept="image/png, image/jpeg,image/jpg,image/heif,image/heic"
                          />
                          <label for="imageUpload" className="img-upload-icon">
                            <i className="ri-pencil-line"></i>
                          </label>
                        </div>
                        <Avatar
                          className="avatar-preview"
                          src={
                            image != "" ? image : ImageURL + values.dishImage
                          }
                        />
                        {/* <div className="avatar-preview" style={{ backgroundImage: `url${image != "" ? image : values.dishImage}`, margin: 10 }}>

                                                </div> */}
                      </div>
                      <span
                        className="text-danger"
                        style={{ textAlign: "center" }}
                      >
                        <ErrorMessage name="dishImage" />
                      </span>
                    </div>
                  </div>
                  <div className="combine-group d-flex ">
                    <div className="mb-3">
                      <label className="form-label">Dish Name *</label>
                      <Field
                        type="text"
                        name="name"
                        placeholder="Dish Name"
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.name && touched?.name
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        value={values?.name.replace(/\s\s+/g, " ")}
                      />
                      <span className="text-danger">
                        <ErrorMessage name="name" />
                      </span>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Cuisine *</label>
                      <Field
                        as="select"
                        name="cuisine"
                        required
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.cuisine && touched?.cuisine
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        value={values.cuisine}
                      >
                        <option value="">Select</option>
                        {cuisineType !== undefined &&
                          cuisineType?.map((cuisine) => {
                            return (
                              <option value={cuisine?._id}>
                                {cuisine?.name}
                              </option>
                            );
                          })}
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="cuisine" />
                      </span>
                    </div>
                  </div>
                  <div className="combine-group d-flex ">
                    <div className="mb-3">
                      <label className="form-label">Price *</label>
                      <Field
                        type="text"
                        name="price"
                        placeholder="$0.00"
                        value={`$${values?.price.replace(/ +/g, " ")}`}
                        required
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.price && touched?.price
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        onChange={(e) => {
                          let t = e.target.value;

                          t = t.slice(1);
                          let cond =
                            (t.charAt(t.length - 1) === "." &&
                              t.charAt(t.length - 2) === ".") ||
                            t.split(".").length > 2;
                          let zipReg = /[0-9.]+$/;
                          if ((zipReg.test(t) || t === "") && !cond) {
                            setFieldValue(
                              "price",
                              t.indexOf(".") >= 0
                                ? t.substr(0, t.indexOf(".")) +
                                    t.substr(t.indexOf("."), 3)
                                : t
                            );
                          }
                        }}
                      />
                      <span className="text-danger">
                        <ErrorMessage name="price" />
                      </span>
                      <small className="Price-suggestion">
                        <b>
                          You earn <i>${priceChange()}</i> from each dish sold!
                        </b>
                        Wanna Eat collects $
                        {(formik.values.price * chefCommission) / 100
                          ? (
                              (formik.values.price * chefCommission) /
                              100
                            ).toFixed(2)
                          : 0}{" "}
                        ({chefCommission}
                        %) to cover marketing, operations, customer support and
                        software development costs.
                      </small>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Food Type * </label>
                      <Field
                        as="select"
                        name="foodType"
                        required
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.foodType && touched?.foodType
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        value={values.foodType}
                      >
                        <option value="">Select</option>
                        {foodType !== undefined &&
                          foodType?.map((food) => {
                            return (
                              <option value={food?._id}>{food?.name}</option>
                            );
                          })}
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="foodType" />
                      </span>
                    </div>
                  </div>
                  <div className="combine-group d-flex ">
                    <div className="mb-3">
                      <label className="form-label">
                        Portion Size *
                        <span className="tool-tip-box con-tooltip top">
                          <i className="ri-information-line"></i>
                          <div className="tooltip ">
                            <p>
                              Select the size of your dish/container for one
                              serving, so that the customers know what to
                              expect. Additionally, you can elaborate the count
                              of items in your dish or number of people the dish
                              serves, etc using the ‘Dish Description’ field.
                            </p>
                          </div>
                        </span>
                      </label>
                      <Field
                        as="select"
                        name="portionSize"
                        required
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.portionSize && touched?.portionSize
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        value={values.portionSize}
                      >
                        <option value="">Select</option>
                        <option value="4oz">4 oz</option>
                        <option value="8oz">8 oz</option>
                        <option value="12oz">12 oz</option>
                        <option value="16oz">16 oz</option>
                        <option value="24oz">24 oz</option>
                        <option value="32oz">32 oz</option>
                        <option value="Catering Half Tray">
                          Catering Half Tray
                        </option>
                        <option value="Catering Full Tray">
                          Catering Full Tray
                        </option>
                        <option value="Count">
                          Count (see description for count)
                        </option>
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="portionSize" />
                      </span>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        Preparation Time (in mins) *{" "}
                        <span className="tool-tip-box con-tooltip top">
                          <i className="ri-information-line"></i>
                          <div className="tooltip r-tooltip">
                            <p>
                              How much time will it take for you to prepare this
                              dish?
                            </p>
                          </div>
                        </span>
                      </label>
                      <Field
                        type="text"
                        name="preprationTime"
                        placeholder="10"
                        required
                        value={values?.preprationTime?.replace(/ +/g, " ")}
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.preprationTime && touched?.preprationTime
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        onChange={(e) => handleTime(e)}
                      />
                      <span className="text-danger">
                        <ErrorMessage name="preprationTime" />
                      </span>
                    </div>
                    {/* {values?.portionSize === "Count" && (
                        <div className="mb-3">
                           <label className="form-label">
                        Portion Size Count *
                        <span className="tool-tip-box con-tooltip top">
                          <i className="ri-information-line"></i>
                          <div className="tooltip ">
                            <p>
                            Count of dish portion.
                            </p>
                          </div>
                        </span>
                      </label>
                        <Field
                          type="text"
                          name="portionSizeCount"
                          placeholder=""
                          required
                          value={values?.portionSizeCount}
                          className={`form-control ${
                            submitCount > 0
                              ? errors?.portionSizeCount && touched?.portionSizeCount
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                          onChange={(e) => handlePortionSizeCount(e.target.value)}
                        />
                        <span className="text-danger">
                          <ErrorMessage name="portionSizeCount" />
                        </span>   
                        </div>
                      )}           */}
                  </div>
                  <div className="mb-3 textarea-box">
                    <label className="form-label">
                      Dish Description *{" "}
                      <span className="tool-tip-box con-tooltip top">
                        <i className="ri-information-line"></i>
                        <div className="tooltip ">
                          <p>
                            This is your opportunity to describe your dish and
                            tell us what makes it special. So, make it count!
                          </p>
                        </div>
                      </span>
                    </label>
                    <Field
                      component="textarea"
                      rows="4"
                      name="description"
                      value={formik.values.description}
                      required
                      className={`form-control ${
                        submitCount > 0
                          ? errors?.description && touched?.description
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      placeholder="Please describe this dish. What flavors or spices does it include? What makes it special? ex. A traditional pasta of Genoa; this classic Italian dish consists of penne topped with a sauce made up of olive oil, fresh basil, and buttery Pecorino cheese."
                    />
                    <span className="text-danger">
                      <ErrorMessage name="description" />
                    </span>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Ingredients *</label>
                    <small className="inner-text-content">
                      Please arrange the ingredients in descending order i.e.
                      the most used ingredient should be at the top of the list
                      and least used ingredient at the bottom
                    </small>
                  </div>
                  {formValues.map((element, index) => (
                    <div
                      className={
                        start
                          ? "ingredients-box mb-3 active"
                          : "ingredients-box mb-3"
                      }
                    >
                      <div
                        key={index}
                        className="d-flex ingredients-detail "
                        onDragStart={(e) => {
                          setStart(true);
                          dragStart(e, index);
                        }}
                        onDragEnter={(e) => {
                          setStart(false);
                          dragEnter(e, index);
                        }}
                        onDragEnd={drag}
                        draggable
                      >
                        <input
                          type="text"
                          name="ingredients"
                          value={element.replace(/ +/g, " ") || ""}
                          placeholder="Name"
                          onKeyDown={handleEnter}
                          onChange={(e) => handleChange(index, e)}
                          className={`form-control ${
                            submitCount > 0
                              ? errors?.ingredients && touched?.ingredients
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                          ref={inputRef}
                          // autoFocus={inputRef.current.focus()}
                          // inputRef={(ref) => (inputRef.current[index] = ref)}
                        />

                        <div className="action-btn d-flex">
                          <a>{grip}</a>
                          {index >= 0 ? (
                            <a>
                              {" "}
                              <i
                                className="ri-subtract-line"
                                onClick={() => removeFormFields(index)}
                              ></i>
                            </a>
                          ) : null}
                        </div>
                      </div>
                      <span className="text-danger">
                        <ErrorMessage name={`ingredients[${index}]`} />
                      </span>
                    </div>
                  ))}
                  {formValues.length === 0 && (
                    <span className="text-danger">
                      <ErrorMessage name="ingredients" />
                    </span>
                  )}
                  <a
                    className="btn purple-btn trans-btn min-height mb-3 s-btn"
                    onClick={() => addFormFields()}
                    // onKeyDown={handleEnter}
                  >
                    {" "}
                    Add ingredient
                  </a>
                  <div
                    className="mb-3 textarea-box"
                    name="restrictedDiets"
                    onChange={(e) => {
                      setFieldValue("restrictedDiets", e.target.value);
                    }}
                  >
                    <label className="form-label">Dietary Tags </label>
                    <ul key=" ul" className="dietary-list">
                      {tagsData.map((data, index) => {
                        return (
                          <li>
                            <label className="coustom-checkbox">
                              <input
                                checked={data.status}
                                value={data.status}
                                type="checkbox"
                                onChange={(e) => handleChangeTags(index, e)}
                              />
                              <span className="checkmark"></span>
                              <p>{data.title}</p>
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="mb-3 text-center mt-5">
                    <button
                      type="submit"
                      disabled={isDisabled}
                      className="btn purple-btn min-height hvr-sweep-to-right"
                    >
                      Add
                      {/* {Object.keys(formik.errors).length === 0 ? console.log("submit") : tostE("please wait")} */}
                    </button>
                  </div>
                </div>
                {/* <ToastContainer /> */}
              </div>
            </Form>
          </div>
        </FormikProvider>
        {/* </div>
                    </div>
                </div> */}
        {/* <div className="screen-div-info">
                    <ul className="info-list">
                        <li><a href="tel:9513539491"><i className="ri-phone-line"></i>951-353-9491</a></li>
                        <li><a href="mailto:info@email.com"><i className="ri-mail-line"></i>info@email.com</a></li>
                    </ul>
                </div> */}
      </div>
    </>
  );
};

export default AddDish;
